<template>
  <div
    v-if="canEdit || canAllocate"
    class="card d-flex justify-content-end p-2 mb-2"
  >
    <fd-button v-if="canEdit" class="bordered main medium" @click="toEditPage">
      <i class="fas fa-pen mr-1"></i> Edit
    </fd-button>

    <dropdown-button
      v-if="canAllocate"
      button-class="bordered main medium ml-2"
    >
      <template #buttonContent>
        <i class="fas fa-user-gear"></i>
        <i class="fas fa-chevron-down ml-1"></i>
      </template>
      <div class="card">
        <list-item v-if="canAllocateAgent" @click="toAgentAllocationPage">
          Allocate Agent
        </list-item>
        <list-item v-if="canAllocateBanker" @click="toBankerAllocationPage">
          Allocate Banker
        </list-item>
        <list-item v-if="canAllocateLawyer" @click="toLawyerAllocationPage">
          Allocate Lawyer
        </list-item>
      </div>
    </dropdown-button>
  </div>
</template>

<script>
export default {
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    ListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  mixins: [],
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },
    canAllocateAgent: {
      type: Boolean,
      default: false
    },
    canAllocateBanker: {
      type: Boolean,
      default: false
    },
    canAllocateLawyer: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {
    canAllocate() {
      return (
        this.canAllocateAgent ||
        this.canAllocateBanker ||
        this.canAllocateLawyer
      );
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    toEditPage() {
      this.$emit("toEditPage");
    },
    toAgentAllocationPage() {
      this.$emit("toAgentAllocationPage");
    },
    toBankerAllocationPage() {
      this.$emit("toBankerAllocationPage");
    },
    toLawyerAllocationPage() {
      this.$emit("toLawyerAllocationPage");
    }
  }
};
</script>

<style lang="scss"></style>
